<template>
  <div class="ahex-announ-content">
    <div class="ahex-announ-content-item">
      <div class="content" v-if="this.language == 'zh_CN'">
        <span class="header">
          尊敬的Ahex用户，
        </span>
        <span
          >Ahex交易所衷心感谢您过去的信任和支持。但由于业务调整，Ahex交易所将于2023年7月21日起停止全部运营。
        </span>
        <span> 为确保您的利益和资产安全，我们向您强调以下重要事项： </span>
        <span>
          1.我们强烈建议您在2023年7月21日前尽快将账户内的所有资产进行转移。为方便您的操作，我们会保证从即日起至2023年7月21日的这段期间内，平台的提币通道将正常运行。
        </span>
        <span>
          2.自2023年7月21日起，Ahex将全面停止所有充提币业务。届时，您将无法进行任何形式的充值或提现。因此，我们再次提醒您务必在指定日期前完成所有的交易和资产转移。
        </span>
        <span
          >3.关站后，我们的所有业务功能都将停止，包括但不限于充值、提现、交易等功能。对此，请您提前做好完全的准备。
        </span>
        <span
          >4.在资产转移过程中，如您遇到任何问题或需要任何帮助，欢迎您随时通过我们的客服渠道联系我们，我们将尽全力协助。
        </span>
        <span>
          再次感谢您对我们的支持和理解，对由此带来的不便，我们深感抱歉，并祝愿您在未来的投资之路上一切顺利。
        </span>
        <span class="footer">敬请谅解。 </span>
        <span>Ahex交易所团队 </span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="goToHomePage">
            我已知晓
          </el-button>
        </span>
      </div>
      <div class="content" v-else>
        <span class="header">
          Dear Ahex Users,
        </span>
        <span
          >Firstly, we sincerely appreciate your past trust and support for Ahex
          Exchange. However, after careful consideration and difficult
          decisions, we are officially informing that due to internal business
          adjustments, Ahex Exchange will cease all operations starting from
          July 21, 2023.
        </span>
        <span>
          To ensure your interests and the safety of your assets, we would like
          to emphasize the following critical points:</span
        >
        <span>
          1. We strongly advise all users to transfer all assets in your account
          before July 21, 2023. For your convenience, we assure that the
          withdrawal channel on our platform will operate normally from today
          until July 21, 2023.
        </span>
        <span>
          2. From July 21, 2023, Ahex will completely stop all deposit and
          withdrawal services. At that point, you will be unable to carry out
          any form of deposits or withdrawals. Therefore, we kindly remind you
          again to complete all transactions and asset transfers before the
          specified date.
        </span>
        <span
          >3. After closing the recharge and withdrawal business, we will carry
          out internal inventory and business adjustment, and will officially
          close the station after the completion of the operation, then all
          business functions will be discontinued, including, but not limited
          to, recharge, withdrawal, trading and other functions. In this regard,
          please be fully prepared in advance.
        </span>
        <span
          >4. If you encounter any issues or need any assistance during the
          asset transfer process, please feel free to contact us through our
          customer service channels. We will do our utmost to assist you.
        </span>
        <span>
          We understand that this decision may inconvenience you, and we deeply
          apologize for this. We sincerely thank you for your understanding and
          cooperation. We hope to minimize the impact this transition period may
          have on you and wish you all the best on your future investment
          journey.
        </span>
        <span class="footer">Your understanding is greatly appreciated. </span>
        <span> Ahex Exchange Team </span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="goToHomePage"> i know </el-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      ahexDialogVisibleTitle: "Important Announcement",
      ahexDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  created() {
    if (this.language == "zh_CN") {
      this.ahexDialogVisibleTitle =
        "关于Ahex交易所业务调整及停止运营的重要公告";
    }
  },
  methods: {
    goToHomePage() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="scss">
.zh_CN {
  .ahex-announ-content .ahex-announ-content-item .content {
    line-height: 2;
    text-indent: 2em;
    .header {
      text-indent: 0;
    }
  }
}
.ahex-announ-content {
  background: rgba(0, 0, 0, 0.1);
  .ahex-announ-content-item {
    width: 1200px;
    padding: 80px;
    overflow-y: auto;
    background: #fff;
    margin: 0 auto;
    text-align: left;
    height: calc(100vh - 350px);

    .content {
      line-height: 1.6;
      .header {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .footer {
        margin-top: 10px;
      }
      span {
        word-break: break-word;
        display: block;
      }
      .dialog-footer {
        margin: 30px auto 0;
        text-align: center;
        .el-button--primary {
          background: #177cb0;
          border-color: #177cb0;
        }
      }
    }
  }
}
</style>
