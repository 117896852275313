<template>
  <div class="home-page">
    <div
      :class="userInfoShow ? 'not-resiger' : 'is-resiger'"
      class="home-content-one"
      :style="{
        backgroundImage: 'url(' + bannerBg + ') ',
        backgroundSize: 'cover',
      }"
    >
      <!-- <img :src="bannerBg" alt="" class="banner-bg" /> -->
      <div class="banner-content">
        <p class="first-lable" v-if="is8V">
          {{ translateTitle("全球首家元宇宙生态数字资产交易所") }}
        </p>
        <p class="first-lable" v-else-if="isS6">
          S6.com
          {{ translateTitle(projectTitle) }}
        </p>
        <p class="first-lable" v-else>
          {{ projectEnglishName }}
          {{ translateTitle(projectTitle) }}
        </p>

        <p class="second-lable" v-if="isS6">
          {{ translateTitle("合约交易火爆进行中，三重福利大放送") }}
        </p>
        <p class="second-lable" v-else>
          {{ translateTitle("即刻交易比特币、以太坊等上百种数字资产") }}
        </p>
        <div v-show="!userInfoShow" class="regsiter-content">
          <input
            class="regsiter-input"
            v-model="resiterValue"
            type="text"
            :placeholder="translateTitle(`输入用户名/邮箱/手机号`)"
          />
          <span @click="onResiter(1)" class="goRegsiter">{{
            translateTitle("注册")
          }}</span>
        </div>
        <div v-show="userInfoShow" class="regsiter-content">
          <span @click="onTrade" class="goRegsiter theme-btn">{{
            translateTitle("立即交易")
          }}</span>
        </div>
      </div>
    </div>
    <div class="home-content-two" :class="imgList.length == 0 ? '' : ''">
      <div class="swiper-sider-content" v-if="showBanner">
        <div class="swiper-content">
          <div v-if="imgList.length > 4" class="swiper-prev" slot="button-prev">
            <img src="../../assets/img/left-arrow.png" alt="" />
          </div>
          <div class="old-img" v-if="imgList.length <= 4">
            <ul v-for="(item, index) in oldImgList" :key="index">
              <li>
                <span @click="jumpToUrl(item)">
                  <img :src="item.language['img_url_' + language]" alt="" />
                </span>
              </li>
            </ul>
          </div>

          <swiper
            v-else
            :options="swiperOption"
            ref="mySwiper"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-item"
              v-for="(item, index) of imgList"
              :key="index"
            >
              <!-- <span @click="jumpToUrlV2(item)"> -->
              <img
                class="swiper-img"
                :src="item.language['img_url_' + language]"
                :data-href="
                  item.language
                    ? item.language[`url_${language}`]
                    : item.param.url
                "
                alt=""
              />
              <!-- </span> -->
            </swiper-slide>
          </swiper>

          <div v-if="imgList.length > 4" class="swiper-next" slot="button-next">
            <img src="../../assets/img/right-arrow.png" alt="" />
          </div>
        </div>
      </div>
      <div
        v-if="imgList.length >= 4"
        class="swiper-pagination"
        slot="pagination"
      ></div>
      <div class="tips-massage-content">
        <div class="left">
          <i class="iconfont iconxiaoxi"></i>
          <a :href="firstArticles.html_url" target="_blank">
            <span class="article-title">
              {{ firstArticles.title || "--" }}</span
            >
          </a>
        </div>
        <div class="left-driver"></div>
        <div class="bottom">
          <a :href="secondArticles.html_url" target="_blank">
            <span class="article-title">
              {{ secondArticles.title || "--" }}</span
            >
          </a>
        </div>
        <div class="right-driver"></div>
        <div @click="onClickMore" class="right">
          <span>{{ translateTitle("更多") }}</span>
          <i class="iconfont icongengduo"></i>
        </div>
      </div>
      <div class="quotes-content">
        <el-table
          v-loading="tableLoading"
          :data="tableDataComputed"
          row-key="symbol_id"
          style="width: 100%"
          class="trade-table"
          @row-click="onClickRow"
        >
          <el-table-column
            :label="translateTitle('交易对')"
            align="left"
            min-width="140"
            prop="from_symbol"
          >
            <template slot-scope="{ row }">
              <span
                class="coin-content"
                @click="onSymbolClick(row)"
                style="cursor: pointer"
              >
                <img class="coin-img" :src="row.coin_url" alt="" />
                <span
                  class="left-symbol"
                  style="margin-left: 10px; font-size: 14px"
                  >{{ row.from_symbol }}</span
                >
                <span class="dark-color"> / {{ row.to_symbol }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('最新价')"
            align="left"
            prop="usd"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro left-row"> {{ row.last }} </span>
                <span class="dark-color DINPro"
                  >/ {{ systemCurrencyMaps[systemCurrency]
                  }}{{ row.currency[systemCurrency] }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H涨跌幅')"
            align="center"
            prop="info.price_change_percent"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <span
                class="left-row DINPro"
                :class="Number(row.price_change_percent) > 0 ? 'green' : 'red'"
                ><span
                  class="green"
                  v-show="Number(row.price_change_percent) > 0"
                  style="margin-right: -3px"
                >
                  + </span
                >{{ row.price_change_percent }} %</span
              >
            </template>
          </el-table-column>
          <el-table-column
            v-if="!is8coin"
            :label="translateTitle('行情')"
            align="center"
            prop="info.high"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <img
                class="mrket-img"
                v-if="Number(row.price_change_percent) > 0"
                src="../../assets/img/markets/markt-up.png"
              />
              <img
                class="mrket-img"
                v-else
                src="../../assets/img/markets/markt-download.png"
              />
            </template>
          </el-table-column>

          <el-table-column
            :label="translateTitle('操作')"
            align="center"
            min-width="50"
          >
            <template slot-scope="{ row }">
              <el-button class="operate" @click="onSymbolClick(row)">{{
                translateTitle("交易")
              }}</el-button>
            </template>
          </el-table-column>
          <no-data
            slot="empty"
            v-show="!tableLoading && tableDataComputed.length"
          ></no-data>
        </el-table>
        <div class="more-info">
          <a :href="routeSplicing(language, 'markets')" class="more">
            <span class="more-title">{{ translateTitle("查看更多行情") }}</span>
            <i class="iconfont icongengduo"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="home-content-seven" v-if="isHuiXin">
      <p class="content-title">
        {{ translateTitle("选择汇信，探索无限可能") }}
      </p>
      <p class="volume-sub-title">
        {{
          translateTitle(
            "汇信竭力承诺以严格协议和行业领先的技术措施为用户安全保驾护航"
          )
        }}
      </p>
      <div class="mp4-content">
        <div class="video-content">
          <video
            class="video"
            width="900"
            autoplay
            poster="../../assets/img/home/video-page.png"
            controls
            :src="currentVideo"
            frameborder="0"
          ></video>
        </div>
      </div>
    </div>
    <!-- 成交量介绍 -->
    <div class="home-content-three">
      <h1 class="volume-title">
        {{ projectEnglishName }}，{{ translateTitle("近24小时成交量") }}
        <strong class="theme-red" v-if="!isUeasy">1,581,222,781</strong>
        <strong class="theme-red" v-else>{{ allCountMoneyStr }}</strong>
        {{ translateTitle("美元") }}
      </h1>
      <p class="volume-sub-title">
        {{ projectEnglishName }}
        {{
          translateTitle("提供数百种币币和合约交易对，以满足多样化的交易需求。")
        }}
        {{
          translateTitle(
            "平台在200个国家和地区中有超过2千万用户，是全球领先的数字资产服务平台。"
          )
        }}
      </p>
      <div class="point">
        <div class="point-item">
          <p v-if="!isUeasy">100+</p>
          <p v-else>110+</p>
          <span>{{ translateTitle("用户国家分布") }}</span>
        </div>
        <div class="point-item">
          <p v-if="!isUeasy">{{ translateTitle("1500万") }}+</p>
          <p v-else>{{ translateTitle("2100万") }}+</p>
          <span>{{ translateTitle("用户数") }}</span>
        </div>
        <div class="point-item">
          <p v-if="!isUeasy">200+</p>
          <p v-else>230+</p>
          <span>{{ translateTitle("交易对") }}</span>
        </div>
        <div class="point-item">
          <p>7×24h</p>
          <span>{{ translateTitle("服务支持") }}</span>
        </div>
      </div>
    </div>
    <div class="home-content-four">
      <p class="content-title">
        {{ translateTitle("随时随地，多平台终端交易") }}
      </p>
      <div class="four-content">
        <div class="left-content">
          <p class="left-content-title">
            {{ translateTitle("多终端完美兼容，随时满足各种场景的交易需求。") }}
          </p>
          <ul class="tips-details">
            <li class="">
              <i class="iconfont iconsize16-3"></i>
              <span>{{ translateTitle("随时查看实时行情") }}</span>
            </li>
            <li>
              <i class="iconfont iconsize16-3"></i>
              <span>{{
                translateTitle("轻松买卖BTC，ETH，DOGE和其他数字资产")
              }}</span>
            </li>
            <li>
              <i class="iconfont iconsize16-3"></i>
              <span>{{
                translateTitle("获取您关注的加密货币价格变化预警")
              }}</span>
            </li>
            <li>
              <i class="iconfont iconsize16-3"></i>
              <span>
                {{ translateTitle("查看比特币现货、期货、期权价格") }}</span
              >
            </li>
            <li>
              <i class="iconfont iconsize16-3"></i>
              <span>{{ translateTitle("比较各交易所加密货币价格") }}</span>
            </li>
          </ul>
          <div class="download-content">
            <div class="download-item">
              <a :href="android_url" target="_blank" rel="noopener noreferrer">
                <img src="../../assets/img/home/google-down.png" alt="" />
              </a>
            </div>
            <div class="download-item">
              <a :href="appstore_url" target="_blank" rel="noopener noreferrer">
                <img src="../../assets/img/home/app-down.png" alt="" />
              </a>
            </div>
            <div
              class="download-item qr-code"
              @mouseenter="onShowDownLoad"
              @mouseleave="onHiddenDownLoad"
            >
              <img src="../../assets/img/home/down-btn.png" alt="" />
            </div>
          </div>
          <div v-show="showDownLoadImg" class="download-code">
            <img class="qr_code_canvas" :src="appDownLoadImg" alt="" />
            <!-- <qrcode-vue
              class="qr_code_canvas"
              v-show="appDownLoadImg"
              :value="appDownLoadImg"
              :size="100"
              level="H"
            ></qrcode-vue> -->
            <div class="scren-code">{{ translateTitle("扫码下载") }}</div>
          </div>
        </div>
        <div class="right-content" v-if="is8V">
          <img src="../../assets/img/home/trade-img2.png" alt="" />
        </div>
        <div class="right-content" v-else-if="isUeasy">
          <img src="../../assets/img/home/trade-img-ueasy.png" alt="" />
        </div>
        <div class="right-content" v-else-if="isHuiXin">
          <img src="../../assets/img/home/trade-img3.png" alt="" />
        </div>
        <div class="right-content" v-else-if="is5A">
          <img src="../../assets/img/home/trade-img-5a.png" alt="" />
        </div>
        <div class="right-content" v-else-if="isSpex">
          <img src="../../assets/img/home/trade-img-spex.png" alt="" />
        </div>
        <div class="right-content" v-else-if="is8coin">
          <img src="../../assets/img/home/trade-img-8coin.png" alt="" />
        </div>
        <div class="right-content" v-else-if="isBjiu">
          <img
            v-if="language == 'zh_CN'"
            src="../../assets/img/home/trade-img-bjiu-zh.png"
            alt=""
          />
          <img v-else src="../../assets/img/home/trade-img-bjiu.png" alt="" />
        </div>
        <div class="right-content" v-else>
          <div class="home-bg-logo">
            <img :src="logoImg" alt="" />
          </div>
          <img src="../../assets/img/home/trade-img.png" alt="" />
        </div>
      </div>
    </div>
    <div class="home-content-five" v-if="VUE_UNION_STATION != 'B9'">
      <p class="content-title">{{ translateTitle("开启您的数字货币之旅") }}</p>
      <div class="five-content">
        <div class="details">
          <div class="five-item">
            <!-- <img src="../../assets/img/home/5-1.png" alt="" /> -->
            <div class="fiv-svg">
              <svg
                width="60px"
                height="60px"
                viewBox="0 0 60 60"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>编组</title>
                <defs>
                  <linearGradient
                    x1="36.4213856%"
                    y1="57.6678612%"
                    x2="82.6463036%"
                    y2="23.2771745%"
                    id="linearGradient-1"
                  >
                    <stop
                      class="stop-1"
                      stop-color="#FFFFFF"
                      stop-opacity="0.379753059"
                      offset="0%"
                    ></stop>
                    <stop
                      class="stop-2"
                      stop-color="#E81B40"
                      offset="100%"
                    ></stop>
                  </linearGradient>
                </defs>
                <g
                  id="控件"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g id="编组">
                    <rect id="矩形" x="0" y="0" width="60" height="60"></rect>
                    <circle
                      id="svg-icont"
                      fill="url(#linearGradient-1)"
                      opacity="0.800987607"
                      cx="36.5"
                      cy="26.5"
                      r="21.5"
                    ></circle>
                  </g>
                </g>
              </svg>
              <i class="iconfont iconicon-t1"></i>
            </div>

            <!-- <svg></svg> -->
            <p class="p-content">{{ translateTitle("快捷购买数字货币") }}</p>
            <p class="s-content">
              {{ translateTitle("使用多种支付方式交易数字货币，安全快捷") }}
            </p>
          </div>
          <div class="five-item">
            <!-- <img src="../../assets/img/home/5-2.png" alt="" /> -->

            <!-- <img src="../../assets/img/home/5-1.png" alt="" /> -->
            <div class="fiv-svg">
              <svg
                width="60px"
                height="60px"
                viewBox="0 0 60 60"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>编组</title>
                <defs>
                  <linearGradient
                    x1="36.4213856%"
                    y1="57.6678612%"
                    x2="82.6463036%"
                    y2="23.2771745%"
                    id="linearGradient-1"
                  >
                    <stop
                      class="stop-1"
                      stop-color="#FFFFFF"
                      stop-opacity="0.379753059"
                      offset="0%"
                    ></stop>
                    <stop
                      class="stop-2"
                      stop-color="#E81B40"
                      offset="100%"
                    ></stop>
                  </linearGradient>
                </defs>
                <g
                  id="控件"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g id="编组">
                    <rect id="矩形" x="0" y="0" width="60" height="60"></rect>
                    <circle
                      id="svg-icont"
                      fill="url(#linearGradient-1)"
                      opacity="0.800987607"
                      cx="36.5"
                      cy="26.5"
                      r="21.5"
                    ></circle>
                  </g>
                </g>
              </svg>
              <i class="iconfont iconicon-t2"></i>

              <!-- <svg></svg> -->
            </div>
            <p class="p-content">
              {{ translateTitle("最优的交易费率") }}
            </p>
            <p class="s-content">
              {{
                translateTitle(
                  "优惠的交易费率，富有竞争力的VIP权益，享受最优品质的服务"
                )
              }}
            </p>
          </div>
          <div class="five-item">
            <!-- <img src="../../assets/img/home/5-3.png" alt="" /> -->
            <div class="fiv-svg">
              <svg
                width="60px"
                height="60px"
                viewBox="0 0 60 60"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>编组</title>
                <defs>
                  <linearGradient
                    x1="39.6038733%"
                    y1="57.6678612%"
                    x2="74.9948262%"
                    y2="23.2771745%"
                    id="linearGradient-1"
                  >
                    <stop
                      stop-color="#FFFFFF"
                      stop-opacity="0.379753059"
                      offset="0%"
                    ></stop>
                    <stop stop-color="#E81B40" offset="100%"></stop>
                  </linearGradient>
                </defs>
                <g
                  id="控件"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g id="编组">
                    <rect id="矩形" x="0" y="0" width="60" height="60"></rect>
                    <path
                      d="M34,4 L13,13.0127251 C13.1966591,25.4986687 14.4722855,33.9076001 16.8268793,38.2395196 C19.181473,42.571439 24.9696279,47.1582658 34.191344,52 C43.2004556,47.1582658 48.8610478,42.571439 51.1731207,38.2395196 C53.4851936,33.9076001 54.76082,25.4986687 55,13.0127251 L34,4 Z"
                      id="路径-4"
                      fill="url(#linearGradient-1)"
                      opacity="0.800987607"
                    ></path>
                  </g>
                </g>
              </svg>
              <i class="iconfont iconicon-t3"></i>
            </div>

            <p class="p-content">
              {{ translateTitle("可信赖的安全保障") }}
            </p>
            <p class="s-content">
              {{
                translateTitle(
                  "我们复杂的安全措施和SAFU基金保护您的数字资产免受所有风险。"
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-five-anoth" v-else>
      <div class="hc5-c">
        <p class="h5c-title">{{ translateTitle("合作支持") }}</p>
        <div class="h5c-items">
          <a class="hi-item" href="https://t.me/b9service" target="_blank">{{
            translateTitle("上币发币")
          }}</a>
          <a
            class="hi-item"
            href="https://chatlink.mstatik.com/widget/standalone.html?eid=cca202ee8dc0e64166175257e1549351"
            target="_blank"
            >{{ translateTitle("交易员申请") }}</a
          >
          <a
            class="hi-item"
            href="https://yb9.zendesk.com/hc/zh-cn/articles/4416171067673-%E6%8E%A8%E5%BB%A3%E4%B8%AD%E5%BF%83%E4%BD%BF%E7%94%A8%E8%AA%AA%E6%98%8E"
            target="_blank"
            >{{ translateTitle("手续费返佣") }}</a
          >
          <a
            class="hi-item"
            href="https://yb9.zendesk.com/hc/zh-cn/articles/9060167299737-%E6%A8%A1%E6%93%AC%E7%9B%A4%E6%93%8D%E4%BD%9C%E6%96%87%E5%9C%96%E6%95%99%E7%A8%8B"
            target="_blank"
            >{{ translateTitle("模拟盘资金") }}</a
          >
          <a
            class="hi-item"
            href="https://yb9.zendesk.com/hc/zh-cn/articles/7829531460249-B9%E5%85%A8%E7%90%83%E5%A4%A7%E4%BD%BF%E6%8B%9B%E5%8B%9F-%E5%A4%9A%E9%87%8D%E9%99%84%E9%8C%84%E8%A3%9C%E8%B2%BC-%E5%AE%98%E6%96%B9%E8%B3%87%E6%BA%90%E6%89%B6%E6%8C%81-%E5%90%88%E5%A4%A5%E4%BA%BA%E5%88%86%E7%B4%85%E5%BE%85%E9%81%87-%E4%B8%80%E8%B5%B7%E9%82%81%E5%90%91%E4%BA%8B%E6%A5%AD%E5%B7%94%E5%B3%B0"
            target="_blank"
            >{{ translateTitle("KOL品牌大使") }}</a
          >
          <a
            class="hi-item"
            href="https://yb9.zendesk.com/hc/zh-cn/articles/10051607897113-ios%E4%B8%8B%E8%BC%89%E6%95%99%E7%A8%8B"
            target="_blank"
            >{{ translateTitle("iOS安装指南") }}</a
          >
          <a class="hi-item" href="https://t.me/B9Crypto" target="_blank">{{
            translateTitle("B9官方验证渠道")
          }}</a>
          <a
            class="hi-item"
            href="https://yb9.zendesk.com/hc/zh-cn/articles/8979026644121-B9%E4%BB%A3%E7%90%86-%E4%BA%A4%E6%98%93%E5%93%A1%E6%8B%9B%E5%8B%9F-%E5%A4%9A%E9%87%8D%E9%99%84%E9%8C%84%E8%A3%9C%E8%B2%BC-%E5%AE%98%E6%96%B9%E8%B3%87%E6%BA%90%E6%89%B6%E6%8C%81-%E8%B1%90%E5%8E%9A%E5%BA%95%E8%96%AA-%E9%AB%98%E9%A1%8D%E6%8F%90%E6%88%90-%E4%B8%80%E8%B5%B7%E9%82%81%E5%90%91%E4%BA%8B%E6%A5%AD%E5%B7%94%E5%B3%B0"
            target="_blank"
            >{{ translateTitle("精英招募") }}</a
          >
          <a
            class="hi-item"
            href="https://docs.google.com/forms/d/1pGUB1LueoZmGNxdG-q1EYFwFgZWyJ99URXRE8qYJv74/prefil"
            target="_blank"
            >{{ translateTitle("加入我们") }}</a
          >
        </div>
      </div>
    </div>
    <div v-show="!userInfoShow" class="home-content-six">
      <div class="six-content">
        <p class="six-content-title">
          {{
            translateTitle(
              "10s快速创建您的账号，开启20倍收益杠杆交易，100倍收益合约交易"
            )
          }}
        </p>
        <div class="regsiter-content">
          <input
            v-model="resiterVal"
            type="text"
            :placeholder="translateTitle('输入用户名/邮箱/手机号')"
          />
          <span @click="onResiter(2)" class="goRegsiter theme-btn">{{
            translateTitle("注册")
          }}</span>
        </div>
      </div>
    </div>
    <div class="ux-invite-content" v-if="isUeasy" @click="onInviteClick"></div>

    <el-dialog
      :title="ahexDialogVisibleTitle"
      :visible.sync="ahexDialogVisible"
      width="35%"
      custom-class="ahex-dialog-visible"
      center
    >
      <div class="content" v-if="this.language == 'zh_CN'">
        <span class="header">
          尊敬的Ahex用户，
        </span>
        <span
          >Ahex交易所衷心感谢您过去的信任和支持。但由于业务调整，Ahex交易所将于2023年7月21日起停止全部运营。
        </span>
        <span> 为确保您的利益和资产安全，我们向您强调以下重要事项： </span>
        <span>
          1.我们强烈建议您在2023年7月21日前尽快将账户内的所有资产进行转移。为方便您的操作，我们会保证从即日起至2023年7月21日的这段期间内，平台的提币通道将正常运行。
        </span>
        <span>
          2.自2023年7月21日起，Ahex将全面停止所有充提币业务。届时，您将无法进行任何形式的充值或提现。因此，我们再次提醒您务必在指定日期前完成所有的交易和资产转移。
        </span>
        <span
          >3.关站后，我们的所有业务功能都将停止，包括但不限于充值、提现、交易等功能。对此，请您提前做好完全的准备。
        </span>
        <span
          >4.在资产转移过程中，如您遇到任何问题或需要任何帮助，欢迎您随时通过我们的客服渠道联系我们，我们将尽全力协助。
        </span>
        <span>
          再次感谢您对我们的支持和理解，对由此带来的不便，我们深感抱歉，并祝愿您在未来的投资之路上一切顺利。
        </span>
        <span class="footer">敬请谅解。 </span>
        <span>Ahex交易所团队 </span>
      </div>
      <div class="content" v-else>
        <span class="header">
          Dear Ahex Users,
        </span>
        <span
          >Firstly, we sincerely appreciate your past trust and support for Ahex
          Exchange. However, after careful consideration and difficult
          decisions, we are officially informing that due to internal business
          adjustments, Ahex Exchange will cease all operations starting from
          July 21, 2023.
        </span>
        <span>
          To ensure your interests and the safety of your assets, we would like
          to emphasize the following critical points:</span
        >
        <span>
          1. We strongly advise all users to transfer all assets in your account
          before July 21, 2023. For your convenience, we assure that the
          withdrawal channel on our platform will operate normally from today
          until July 21, 2023.
        </span>
        <span>
          2. From July 21, 2023, Ahex will completely stop all deposit and
          withdrawal services. At that point, you will be unable to carry out
          any form of deposits or withdrawals. Therefore, we kindly remind you
          again to complete all transactions and asset transfers before the
          specified date.
        </span>
        <span
          >3. After closing the recharge and withdrawal business, we will carry
          out internal inventory and business adjustment, and will officially
          close the station after the completion of the operation, then all
          business functions will be discontinued, including, but not limited
          to, recharge, withdrawal, trading and other functions. In this regard,
          please be fully prepared in advance.
        </span>
        <span
          >4. If you encounter any issues or need any assistance during the
          asset transfer process, please feel free to contact us through our
          customer service channels. We will do our utmost to assist you.
        </span>
        <span>
          We understand that this decision may inconvenience you, and we deeply
          apologize for this. We sincerely thank you for your understanding and
          cooperation. We hope to minimize the impact this transition period may
          have on you and wish you all the best on your future investment
          journey.
        </span>
        <span class="footer">Your understanding is greatly appreciated. </span>
        <span> Ahex Exchange Team </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ahexDialogVisible = false">{{
          translateTitle("取消")
        }}</el-button>
        <el-button type="primary" @click="ahexDialogVisible = false">{{
          translateTitle("确定")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import Socket from "@/assets/js/socket.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { apiGetStaticTableInfo } from "@/model/settings";
import NoData from "@/components/common/NoData";
import Cookies from "js-cookie";
import { apiGetBannerList, apiGetMainV2 } from "@/model/home/index";
import { mapActions, mapGetters } from "vuex";
import { apiWsUrl, apiGetSpotList } from "@/model/exchange/index";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import QrcodeVue from "qrcode.vue";
import axios from "axios";
import routeSplicing from "@/assets/js/routeSplicing";
import globalFun from "@/assets/js/globalFun.js";
var vm = null;
import {
  VUE_APP_ID,
  VUE_CHANNEL_ID,
  VUE_UNION_STATION,
} from "@/assets/js/stationSetting";
export default {
  mixins: [commonMixin],
  components: {
    swiper,
    swiperSlide,
    // QrcodeVue,
    NoData,
  },

  data() {
    return {
      tt: {}, //WS延时触发器
      lockReconnect: false, //避免重复连接
      wsContentTime: 0,
      detailsUrl: "",
      systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      ahexDialogVisibleTitle: "Important Announcement",
      activeName: "1",
      bannerBg: require("../../assets/svg/bg.svg"),
      // realIndex:0,
      isHuiXin: false,
      is8V: false,
      isUeasy: false,
      isS6: false,
      is5A: false,
      is8coin: false,
      isSpex: false,
      isBjiu: false,
      sideLen: 4,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      ahexDialogVisible: false,
      showDownLoadImg: false,
      showBanner: false,
      android_url: "",
      appstore_url: "",
      currentVideo:
        "https://static.topcreditbank.com/video/about/topcredit-cn.mp4",
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",

      firstArticles: {},
      secondArticles: {},
      swiperOption: {
        initialSlide: 0,

        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
        // on: {
        //   click: function() {
        //     // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
        //     // console.log(this); // -> Swiper
        //     // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
        //     const realIndex = this.realIndex;
        //     vm.handleClickSlide(realIndex);
        //   }
        // },
        on: {
          click: function(e) {
            window.open(e.target.dataset.href);
          },
        },

        // slidesPerView: 1,
        spaceBetween: 24,
        slidesPerView: 4,
        loopedSlides: 8,
        slidesPerGroup: 1,

        // slidesPerView: 'auto',
        // // spaceBetween: 10,
        // // slidesPerView: "auto",
        // loopedSlides: 1,
        // slidesPerGroup: 'auto',
        loopFillGroupWithBlank: true,
        infinite: 1, // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
        autoplayDisableOnInteraction: false,
        // //循环
        loop: true,
        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },
      oldImgList: [
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-1.png"),
            img_url_en: require("../../assets/img/home/banner-1.png"),
            img_url_ja: require("../../assets/img/home/banner-1.png"),
            img_url_ko: require("../../assets/img/home/banner-1.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-1.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-1.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-2.png"),
            img_url_en: require("../../assets/img/home/banner-2.png"),
            img_url_ja: require("../../assets/img/home/banner-2.png"),
            img_url_ko: require("../../assets/img/home/banner-2.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-2.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-2.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-3.png"),
            img_url_en: require("../../assets/img/home/banner-3.png"),
            img_url_ja: require("../../assets/img/home/banner-3.png"),
            img_url_ko: require("../../assets/img/home/banner-3.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-3.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-3.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-4.png"),
            img_url_en: require("../../assets/img/home/banner-4.png"),
            img_url_ja: require("../../assets/img/home/banner-4.png"),
            img_url_ko: require("../../assets/img/home/banner-4.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-4.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-4.png"),
          },
          param: { url: "" },
        },
      ],
      imgList: [],
      articlesList: [],
      tableDataComputed: [],
      tempMarkList: [],
      exchangeCoinMap: {},
      coinInfoList: {},
      isAhex: false,
      tableLoading: true,
      tableData: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      logoImg: "",
      timer: null,
      allCountMoney: 1884351973,
      allCountMoneyStr: "1,884,351,973",
      globalFun,
      VUE_UNION_STATION,
    };
  },
  async created() {
    this.getTopBannerImg();
    if (this.language == "zh_CN") {
      this.currentVideo =
        "https://static.topcreditbank.com/video/about/topcredit-cn.mp4";
    } else if (this.language == "zh_HK") {
      this.currentVideo =
        "https://static.topcreditbank.com/video/about/topcredit-tw.mp4";
    } else {
      this.currentVideo =
        "https://static.topcreditbank.com/video/about/topcredit-en.mp4";
    }
    if (VUE_UNION_STATION == "TopCredit") {
      this.isHuiXin = true;
      this.logoImg = require("../../assets/img/home/top-logo.png");
    } else if (VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.oldImgList.forEach(ele => {
        for (const key in ele.language) {
          ele.language[key] = require("../../assets/img/home/banner-5.png");
        }
      });
      this.isBjiu = true;
      this.logoImg = require("../../assets/img/home/bijiu-logo.png");
    } else if (VUE_UNION_STATION == "EverTrust") {
      this.logoImg = require("../../assets/img/home/everTrust-logo-en.png");
    } else if (VUE_UNION_STATION == "100Ex") {
      this.logoImg = require("../../assets/img/logo_100.png");
    } else if (VUE_UNION_STATION == "8V") {
      this.is8V = true;
      this.logoImg = require("../../assets/img/logo_8V.png");
    } else if (VUE_UNION_STATION == "UEEx") {
      this.isUeasy = true;
      this.logoImg = require("../../assets/img/logo_UEEx.png");
      let _that = this;
      setInterval(() => {
        _that.allCountMoney =
          _that.allCountMoney +
          parseInt(Math.random() * (2500 - 1500 + 1) + 1500, 10);

        _that.$nextTick(() => {
          _that.allCountMoneyStr = _that.globalFun.money_format(
            _that.allCountMoney
          );
        });
      }, 6000);
    } else if (VUE_UNION_STATION == "Ahex") {
      this.isAhex = true;
      this.ahexDialogVisible = true;
      this.logoImg = require("../../assets/img/logo_Ahex.png");
      if (this.language == "zh_CN") {
        this.ahexDialogVisibleTitle =
          "关于Ahex交易所业务调整及停止运营的重要公告";
      }
    } else if (VUE_UNION_STATION == "5A") {
      this.is5A = true;
      this.logoImg = require("../../assets/img/logo_5A.png");
      //根据国际化变更title
      document.getElementsByTagName("title")[0].innerText = this.translateTitle(
        "交易比特币BTC、以太币ETH和altcoin | 加密货币交易平台 | 5A数字交易所"
      );
    } else if (VUE_UNION_STATION == "bitda") {
      this.logoImg = require("../../assets/img/logo_bitda.png");
    } else if (VUE_UNION_STATION == "S6") {
      this.isS6 = true;
      this.logoImg = require("../../assets/img/logo_S6.png");
    } else if (VUE_UNION_STATION == "easy") {
      this.logoImg = require("../../assets/img/logo_easy.png");
    } else if (VUE_UNION_STATION == "spex") {
      this.isSpex = true;
      this.logoImg = require("../../assets/img/logo_spex.png");
    } else if (VUE_UNION_STATION == "8coin") {
      this.is8coin = true;
      this.logoImg = require("../../assets/img/logo_spex.png");
    } else if (VUE_CHANNEL_ID > 2000) {
      this.logImg = require("../../assets/img/logo_doge.png");
      this.slogan = "数字理财 赢得未来";
    } else {
      this.logoImg = require("../../assets/img/home/top-logo.png");
    }
    await this.getBannerData();

    await this.getExchangeCoinMap();

    let qrcode_download = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let about = JSON.parse(sessionStorage.getItem("about"));
    // this.appDownLoadImg = VUE_APP_APP + "/Dw/AppDownload?app_id=" + VUE_APP_ID;
    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    this.appDownLoadImg = qrcode_download.url;

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
      this.detailsUrl =
        zendesk_notice.language[`articles_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
      this.detailsUrl = zendesk_notice.articles_url;
    }

    await this.getStaticTableInfo(1);
    this.getDeatilsData();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.tableLoading = true;
      setTimeout(() => {
        this.initWS();
      }, 2000);
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.tableLoading = true;
      setTimeout(() => {
        this.initWS();
      }, 2000);
    }
    vm = this;
    // await this.getItemList();

    // setTimeout(() => {
    //   this.initWS();
    // }, 1000);
    this.getTradePairList();
    this.voucherInterval = setInterval(() => {
      this.getBannerData();
      this.getDeatilsData();
    }, 5000 * 60 * 6);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },

  methods: {
    routeSplicing,
    onInviteClick() {
      if (this.userInfo) {
        this.$router.push(
          this.routeSplicing(this.language, "ucenter/spread-center")
        );
      } else {
        this.$router.push(this.routeSplicing(this.language, "login"));
      }
    },
    jumpToUrl(item) {
      if (item.language) {
        window.open(item.language[`url_${this.language}`]);
      } else {
        window.open(item.param.url);
      }
    },
    jumpToUrlV2(item) {
      if (item.language) {
        window.open(item.language[`url_${this.language}`]);
      } else {
        window.open(item.param.url);
      }
    },
    getMainV2() {
      let params = {
        channel: 0,
      };
      apiGetMainV2(params).then(res => {
        alert(res.status);
        if (res.status == 200) {
          // console.log(res.data);
        }
      });
    },
    async getExchangeCoinMap() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObj));
      }
    },

    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
        this.android_url = data.android_url;
        this.appstore_url = data.appstore_url;
      }
    },
    handleClick() {},
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    onResiter(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.resiterValue;
      } else {
        inputVal = this.resiterVal;
      }
      var emailReg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      ); //正则表达式
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal,
        },
      });
    },
    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
    onLookMarket() {
      this.$router.push(this.routeSplicing(this.language, "markets"));
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
    async getTopBannerImg() {
      const req = {
        type: 1,
        title: "main_top",
      };
      const { status, data } = await apiGetBannerList(req);
      if (status == 200 && data.length > 0) {
        this.bannerBg = data[0].img_url;
      }
    },
    async getBannerData() {
      const req = {
        type: 1,
        title: "main",
      };
      const { status, data } = await apiGetBannerList(req);
      if (status == 200) {
        let len = data.length;
        if (len <= 4) {
          data.forEach((ele, index) => {
            this.oldImgList[index] = ele;
          });
        } else {
          this.imgList = data;
        }
        this.showBanner = true;
        this.sideLen = len >= 4 ? 4 : len;
      }
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },

    async getDeatilsData() {
      var that = this;
      axios
        .get(this.detailsUrl)
        .then(function(response) {
          that.articlesList = response.data.articles;
          if (response.data.articles.length >= 1) {
            that.firstArticles = response.data.articles[0];
          } else {
            that.firstArticles.html_url = "";
            that.firstArticles.title = "";
          }
          if (response.data.articles.length >= 2) {
            that.secondArticles = response.data.articles[1];
          } else {
            that.secondArticles.html_url = "";
            that.secondArticles.title = "";
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getTradePairList() {
      apiGetSpotList().then(res => {
        if (res.status == 200) {
          let tempArr = res.data;
          tempArr.forEach(ele => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.exchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[0]
                  .toUpperCase();
                ele["to_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[1]
                  .toUpperCase();
                ele["info"] = this.exchangeCoinMap[key];
                ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
                this.tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (
                  this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
                ) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          let tempList = this.tempMarkList.filter(ele => {
            return ele.from_symbol != undefined && ele.web_recommend == 1;
          });
          this.tableDataComputed = tempList.sort(
            this.compareOrderBy("order_by")
          );
        }
      });
    },
    compareOrderBy(property) {
      return function(a, b) {
        if (
          a &&
          b &&
          a["info"] &&
          b["info"] &&
          a["info"][property] &&
          b["info"][property]
        ) {
          let value1 = a["info"][property];
          let value2 = b["info"][property];
          if (value1 - value2 != 0) {
            return value1 - value2;
          } else {
            let temp1 = a["from_symbol"];
            let temp2 = b["from_symbol"];
            if (temp1 < temp2) {
              // 按某种排序标准进行比较, a 小于 b
              return -1;
            }
            if (temp1 > temp2) {
              return 1;
            }
            return 0;
          }
        } else {
          return 0;
        }
      };
    },
    initWS() {
      let me = this;
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: VUE_APP_ID,
          },
        },
        callback: this.onResponse,
        onConnect,
      };
      window.USDT_WS = this.ws = new Socket(option).getInstance();
      me.global.setWs(this.ws);
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWS();
          me.lockReconnect = false;
        }, 3000);
      }
    },
    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.marketM") {
        this.tableData = data.data;

        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach(ele => {
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[0]
                .toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[1]
                .toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (
                this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
              ) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        let tempList = this.tableData.filter(ele => {
          return ele.from_symbol != undefined && ele.web_recommend == 1;
        });
        this.tableDataComputed = tempList.sort(this.compareOrderBy("order_by"));
      }
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onSymbolClick(row) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      // let tempUrl = this.routeSplicing(
      //   this.language,
      //   `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      // );
      // window.open(tempUrl);
    },
  },
};
</script>
<style lang="scss">
.ar {
  .home-page .el-table tbody .cell .coin-content {
    float: right;
    margin-right: 80px;
    .coin-img {
      margin-left: 10px;
    }
  }

  .home-page .home-content-four .left-content .tips-details li i {
    margin-left: 6px;
    margin-right: 0;
  }
  .home-page .home-content-four .left-content .download-content {
    direction: ltr;
  }
  .home-page .home-content-four .left-content .download-code {
    height: 160px;
    .qr_code_canvas {
      margin-right: 0;
    }
  }
}
.zh_CN {
  .home-page .ahex-dialog-visible .el-dialog__body .content {
    line-height: 2;
    text-indent: 2em;
    .header {
      text-indent: 0;
    }
  }
  .home-page .ux-invite-content {
    background: url("../../assets/img/home/invite.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
}
.zh_HK {
  .home-page .ux-invite-content {
    background: url("../../assets/img/home/invite.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
}
.home-page {
  margin-top: -2px;
  // height: 2977px;
  display: block;
  [v-cloak] {
    display: none;
  }
  .ux-invite-content {
    cursor: pointer;
    width: 238px;
    height: 193px;
    background: url("../../assets/img/home/invite-en.png") 0 0 no-repeat;
    background-size: 100% 100%;
    position: fixed;
    top: 45%;
    right: 30px;
  }
  .fiv-svg {
    position: relative;
    i {
      z-index: 900;
      position: absolute;
      font-size: 60px;
      top: -2px;
      left: 110px;
    }
  }

  // font-family: PingFangSC, BinancePlex, Arial, sans-serif !important;
  .is-resiger {
    height: 340px;
  }
  .no-resiger {
    height: 340px;
  }
  user-select: none;
  padding: 0;
  // min-height: 2000px;
  flex: 1;
  .swiper-img {
    width: 100%;
    height: 144px;
    border-radius: 6px;
  }

  .home-content-one {
    // background: url("../../assets/img/home/home-bg.png") 0 0 repeat;
    background: url("../../assets/svg/bg.svg") 0 0 repeat;
    padding-bottom: 30px;
    min-height: 340px;
    background-size: cover;
    // position: relative;
    .banner-bg {
      width: 100%;
      height: 340px;
      position: absolute;
    }
    .banner-content {
      position: relative;
      z-index: 2;
    }

    .first-lable {
      color: white;
      font-size: 38px;
      font-weight: bolder;
      text-align: center;
      padding-top: 80px;
    }
    .second-lable {
      color: #d9dad8;
      font-size: 14px;
      text-align: center;
      padding-top: 15px;
    }
  }
  .home-content-two {
    min-height: 708px;
    background: #f8f9fd;
    padding-bottom: 50px;
    &.V2 {
      min-height: 588px;
    }
    .swiper-sider-content {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      position: static;
      .swiper-next {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        background-color: rgb(0, 0, 0, 0.4);
        right: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        img {
          height: 17px;
          width: 10px;
        }
      }
      .swiper-prev {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        background-color: rgb(0, 0, 0, 0.4);
        left: 0px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        img {
          height: 17px;
          width: 10px;
        }
      }
      .swiper-content {
        display: flex;
        position: relative;
        align-items: center;
        width: 1200px;
        .button-prev {
          margin-right: 13px;
        }
        i {
          &:focus {
            border: 0px;
            outline: 0px;
          }
        }
        .iconfont {
          font-size: 28px;
          color: #747a7e;
          outline: none;
          cursor: pointer;
          border: 0px;
        }
        .swiper-container {
          width: 1200px;
          height: 144px;
          position: relative;
          border-radius: 6px;
          .swiper-wrapper {
            text-align: center !important;
          }

          img {
            cursor: pointer;
            // width: 282px;
            height: 144px;
            // opacity: 0.7;
            box-sizing: border-box;
            opacity: 1;
            &:hover {
              opacity: 1;
            }
          }
          .swiper-slide {
            margin-left: 0px !important;
          }
        }
      }
      .old-img {
        display: flex;
        width: 1200px;
        ul {
          // width: 1110px;
          &:last-child {
            li img {
              margin-right: 0;
            }
          }
          li {
            cursor: pointer;
            // width: 282px;
            // padding-right: 20px;
          }
        }

        img {
          width: 282px;
          height: 144px;
          margin-right: 24px;
          border-radius: 5px;
        }
      }
    }
    .swiper-pagination {
      position: relative !important;
      margin-top: 15px;
      span {
        margin: 0px 10px;
        // color: #9aa1b1;
      }
    }

    .tips-massage-content {
      padding: 20px 0px;
      font-size: 14px;
      color: #080c34;
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        // min-width: 265px;
        // width: auto;
        i {
          font-size: 16px;
          color: #080c34;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .left-driver {
        width: 1px;
        height: 18px;
        margin: 2px 15px 0;
        background-color: #c2c7d0;
      }

      .bottom {
        // min-width: 200px;
        // margin-left: 15px;
        text-align: left;
      }
      .article-title {
        color: #080c34 !important;
        min-width: 250px;
        display: inline-block;
        vertical-align: middle;
        max-width: 550px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .right-driver {
        width: 1px;
        height: 18px;
        margin: 2px 20px 0 15px;
        background-color: #c2c7d0;
      }
      .right {
        cursor: pointer !important;
        i {
          font-size: 14px;
          color: #9aa1b1;
          margin-top: 2px;
          &:hover {
            color: #3a7dff;
          }
        }
        &:hover {
          color: #3a7dff;
          i {
            color: #3a7dff;
          }
        }
      }
    }
    .quotes-content {
      width: 1200px;
      // height: 572px;
      margin: 0 auto;
      padding: 20px 20px 0px;
      border-radius: 6px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
      .left-symbol {
        color: #354052;
        font-size: 14px;
        font-weight: 500;
      }
      .left-row {
        color: #354052;
        font-size: 14px;
        font-weight: 600;
      }

      .el-table {
        // min-height: 430px !important;
        tr {
          cursor: pointer;
        }
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f1f5f6;
      }
      .more-info {
        display: flex;
        align-items: center;
        height: 72px;
        justify-content: center;

        text-align: center;
        font-size: 16px;
        color: #7f8490;
        .more-title {
          width: 102px;
        }
        .more {
          cursor: pointer;
          &:hover {
            color: #3a7dff;
            span {
              color: #3a7dff;
            }
          }
        }

        span {
          color: #9aa1b1;
        }
        .icongengduo {
          font-size: 14px;
          // margin-top: 4px;
        }
      }

      .el-table {
        min-height: 300px;
        .el-icon-star-off {
          cursor: pointer;
        }
      }
      .el-table::before {
        height: 0;
      }
    }
  }
  .home-content-three {
    // background-color: #17232c;
    background: url("../../assets/svg/bg.svg") 0 0 repeat;
    text-align: center;
    // overflow: hidden;
    height: 350px;
    .volume-title {
      font-size: 32px;
      font-weight: 600;
      color: #fff;
      line-height: 45px;
      padding: 75px 0 12px;
      .theme-red {
        color: #c61b3d;
      }
    }

    .volume-sub-title {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      line-height: 30px;
    }

    .point {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 45px auto 80px;

      &-item {
        display: flex;
        flex-direction: column;
        margin-right: 175px;
        p {
          color: #ffffff;
          font-size: 36px;
          font-weight: 600;
          height: 50px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.5;
          line-height: 25px;
          // margin-top: 15px;
        }
      }

      &-item:last-child {
        margin-right: 0;
      }
    }
  }
  .home-content-four {
    height: 600px;
    text-align: center;
    padding-bottom: 50px;

    .content-title {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      margin: 60px 0 62px;
      color: #354052;
    }
    .left-content {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      position: relative;
      width: 400px;
      // min-width: 420px;
      // max-width: 500px;
      margin-right: 80px;
      .tips-details {
        li {
          display: flex;
          i {
            margin-top: 3px;
            margin-right: 6px;
            color: #c61b3d;
          }
        }
      }

      .left-content-title {
        font-size: 18px;
        font-weight: 600;
        // width: 20px;
        color: #1f2533;
        margin-bottom: 25px;
      }
      li {
        margin-bottom: 14px;
        color: #354052;
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
      .download-content {
        margin-top: 40px;
        display: flex;
        position: relative;
        .download-item {
          img {
            width: 150px;
            height: 50px;
          }
        }
        .qr-code {
          width: 55px;
          height: 50px;
          background-color: #1e2434;
          padding: 13px 15px 12px;
          border-radius: 3px;

          img {
            cursor: pointer;
            text-align: center;
            width: 25px;
            height: 25px;
            color: #ffffff;
          }
        }
      }
      .download-code {
        // float: right;
        // margin-right: 60px;
        position: absolute;
        right: 0px;
        box-sizing: border-box;
        width: 120px;
        height: 145px;
        border-color: #eaecef;
        margin-top: 10px;
        background: #ffffff;
        padding: 10px;
        border-radius: 4px;
        z-index: 900;

        img {
          width: 100px;
          height: 100px;
        }
        .scren-code {
          width: 100%;
          margin-top: 5px;
          text-align: center;
          box-sizing: border-box;
          color: #1e2329;
          font-size: 14px;
        }
      }
    }
    .right-content {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 718px;
      height: 420px;
      .home-bg-logo {
        position: absolute;
        top: 21px;
        left: 6px;
        img {
          height: 16px;
        }
      }
    }
  }
  .home-content-five {
    height: 370px;

    .content-title {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      margin: 60px 0 62px;
      color: #1f2533;
    }
    .five-content {
      display: flex;
      justify-content: center;
      .details {
        display: flex;
        width: 1120px;
        justify-content: space-between;
      }
      .five-item {
        text-align: center;
        // width: 417px;

        img {
          width: 70px;
          height: 70px;
        }
        .p-content {
          font-size: 22px;
          font-weight: 600;
          text-align: center;
          color: #1f2533;
          margin-top: 15px;
          margin-bottom: 25px;
        }
        .s-content {
          margin-bottom: 77px;
          width: 285px;
          text-align: center;
          color: #354052;
          font-size: 14px;
        }
      }
    }
  }
  .home-content-five-anoth {
    // margin-top:
    padding: 50px 0 25px;
    background-color: #f8f9fd;
    display: flex;
    align-items: center;
    justify-content: center;
    .hc5-c {
      max-width: 1290px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .h5c-title {
        font-size: 36px;
        font-weight: 500;
        color: #222220;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .h5c-items {
        margin-top: 41px;
        display: flex;
        flex-wrap: wrap;
        .hi-item {
          margin: 0 26.5px 41px;
          padding: 0 10px;
          cursor: pointer;
          min-width: 205px;
          height: 98px;
          border-radius: 8px;
          box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.07);
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 22px;
          color: #9b9b9b;
          &:hover {
            box-shadow: 0 6px 16px 0 #fcd535;
            background-image: linear-gradient(
              to bottom,
              #f5c913 21%,
              #fcd535 82%
            );
            font-size: 22px;
            font-weight: 500;
            color: #0d1327;
          }
        }
      }
    }
  }
  .home-content-six {
    height: 240px;
    text-align: center;
    background-color: #17232c;
    .six-content {
      height: 240px;
      width: 100%;
      padding: 64px 0 87px;
    }
    //  background: url("../../assets/svg/bg.svg") 0 0 repeat;
    .six-content-title {
      text-align: center;
      color: #ffffff;
      font-size: 22px;
      font-weight: 600;
    }
  }
  .regsiter-content {
    margin: 42px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      display: inline-block;
      width: 370px;
      height: 46px;
      margin: 0 10px 0 0;
      font-size: 16px;
      line-height: 46px;
      font-weight: 500;
      padding: 12px 0px 12px 17px;
      border-radius: 2px;
      background-color: #ffffff;
      &::placeholder {
        color: #dddee1;
      }
    }
    .goRegsiter {
      border-radius: 2px;
      cursor: pointer;
      vertical-align: middle;
      background: #c61b3d;
      text-align: center;
      font-size: 16px;
      display: inline-block;
      width: 130px;
      line-height: 46px;
      height: 46px;
      color: white;
      box-sizing: border-box;
    }
    .trade-btn {
      border-radius: 2px;
      cursor: pointer;
      vertical-align: middle;
      background: #c61b3d;
      text-align: center;
      font-size: 16px;
      display: inline-block;
      width: 130px;
      line-height: 46px;
      height: 46px;
      color: white;
      box-sizing: border-box;
    }
  }

  .el-table {
    padding: 0px;
    .el-table__header {
      thead {
        .cell {
          font-size: 14px;
          color: #7f8490;
        }
      }
    }
    tbody {
      .operate {
        width: 70px;
        height: 28px;
        padding: 4px 0px;
        text-align: center;
        border-radius: 2px !important;
        background-color: #c61b3d;
        border: 1px solid #c61b3d;
        font-weight: 400 !important;
        font-size: 14px;
        box-sizing: border-box;
        span {
          font-weight: 400 !important;
          color: #ffffff !important;
        }
      }
      .mrket-img {
        height: 30px;
      }

      .cell {
        .coin-content {
          display: inline-block;
          .coin-img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
        }
        .red {
          color: #c61b3d;
        }
        .green {
          color: #03ad79;
        }
        .active {
          color: #c61b3d;
          font-size: 18px;
          margin-top: 4px;
          margin-left: -3px;
        }
        .el-icon-star-on,
        .el-icon-star-off {
          text-align: left;
          cursor: pointer;
          margin-right: 10px;
        }
        p,
        span,
        code {
          color: #1f2533;
          // font-weight: 600;
        }
        .el-table__expand-icon {
          display: none;
        }
        .dark-color {
          color: #9aa1b1;
          font-size: 12px;
        }
      }
    }

    .table-expand {
      min-height: 228px;
      padding: 10px;
      display: flex;
      cursor: auto;
      .expand-detail {
        flex: 1;
        display: inline-block;
        margin-right: 32px;
      }
      .el-form {
        display: inline-block;
        width: 300px;
        .el-form-item {
          margin-bottom: 0px;
          padding: 0 20px;
          .el-form-item__label {
            padding: 0;
          }
          &:nth-child(2n + 1) {
            background-color: #f5f5f7;
          }
        }
        .el-form-item__content {
          display: inline-block;
          float: right;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px;
    margin-bottom: 10px;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  .home-content-seven {
    background: #f8f9fd;
    padding-bottom: 120px;

    .content-title {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      color: #354052;
    }
    .volume-sub-title {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      color: #354052;
      margin-top: 10px;
      text-align: center;
      line-height: 30px;
      padding-bottom: 120px;
    }
    .mp4-content {
      position: relative;
      width: 1200px;
      height: 366px;
      margin: 0 auto;
      // background: url("../../assets/img/home/hx-mp4-bg.png") 0 0 repeat;
      .video-content {
        height: 506px;
        width: 900px;
        position: absolute;
        top: -70px;
        left: 150px;
        .video {
        }
      }
    }
  }

  .ahex-dialog-visible {
    .el-dialog__body {
      padding: 25px 35px 30px;
      .content {
        line-height: 1.6;
        .header {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .footer {
          margin-top: 10px;
        }
        span {
          word-break: break-word;
          display: block;
        }
      }
    }
    .el-dialog__footer {
      .el-button--primary {
        background: #177cb0;
        border-color: #177cb0;
      }
    }
  }
}
</style>
