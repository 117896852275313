<template>
  <div class="chat-wedget">
    <el-badge :value="noRead" class="item" :hidden="!noRead" :max="99">
      <div
        class="chat-wedget-circle"
        v-if="!visible"
        @click="onChatClick"
      ></div>
      <p class="chat-wedget-text" v-if="VUE_UNION_STATION == 'Ahex'">
        {{ translateTitle("在线客服") }}
      </p>
    </el-badge>
    <div class="chat-wedget-box" v-if="visible" v-loading="frameLoad">
      <div class="close" @click="onClose">
        <i class="iconfont iconicon-close black" v-if="customer_type == 1"></i>
        <img class="icon" src="../../assets/img/close.png" alt="" />
      </div>
      <iframe id="iframe" :src="frameUrl" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { mapActions, mapGetters } from "vuex";
import { mapState } from "vuex";
import Cookies from "js-cookie";
import { apiGetAssets } from "@/model/exchange/index";
import { apiGetcustermerUrl } from "@/model/home/index";
import { userLoginLogList } from "@/model/ucenter/assets";
import { translateTitle } from "@/assets/js/i18n";
import CryptoJS from "crypto-js";
const md5 = require("md5");
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // const frameUrl = `https://customer-service.hxexchge.com/?token=${token}`;
    return {
      VUE_UNION_STATION,
      visible: false,
      frameUrl: "",
      apiAssetsInfo: "",
      ipInfo: "",
      token: "",
      customer_type: 0,
      customer_service_url: "",
      noRead: "",
      frameLoad: true,
    };
  },
  async created() {
    this.token = Cookies.get("topcredit_exchange_t");
    await this.getcustermerUrl();
    //  this.customer_service_url = sessionStorage.getItem("customer_service_url");
    let tempHost = window.location.host;
    // this.customer_type = sessionStorage.getItem("customer_type");

    if (this.customer_type == "1") {
      this.frameUrl = this.customer_service_url;
    } else {
      this.frameUrl =
        this.customer_service_url +
        `/?token=${this.token}&host=${tempHost}&lang=${this.language}#is_hide`;
    }
    // this.startTimer();
  },
  mounted() {},
  methods: {
    translateTitle,
    async getcustermerUrl() {
      const { status, data } = await apiGetcustermerUrl();
      if (status == 200) {
        if (VUE_UNION_STATION == "MaskEx") {
          this.customer_service_url = "https://direct.lc.chat/13885884";
          this.customer_type = 1;
        } else if (VUE_UNION_STATION == "UEEx") {
          this.customer_type = 1; // 1 第三方客服系统 0 hx 系统
          this.getAssets();
          this.userLoginLogList();
          this.getUeCustomerServer();
        } else {
          this.customer_service_url = data.customer_service_url;
          this.customer_type = data.customer_type;
        }
      }
    },
    onChatClick() {
      this.visible = true;
      // let tempUrl = sessionStorage.getItem("customer_service_url");
      // this.customer_type = sessionStorage.getItem("customer_type");
      let tempHost = window.location.host;

      if (this.customer_type == "1") {
        this.frameUrl = this.customer_service_url;
      } else {
        this.frameUrl =
          this.customer_service_url +
          `/?token=${this.token}&host=${tempHost}&lang=${this.language}#is_show`;
      }
      setTimeout(() => {
        this.frameLoad = false;
      }, 1500);
    },
    onClose() {
      this.visible = false;
      this.frameLoad = true;
      // let tempUrl = sessionStorage.getItem("customer_service_url");
      // this.customer_type = sessionStorage.getItem("customer_type");
      let tempHost = window.location.host;

      if (this.customer_type == "1") {
        this.frameUrl = this.customer_service_url;
      } else {
        this.frameUrl =
          this.customer_service_url +
          `/?token=${this.token}&host=${tempHost}&lang=${this.language}#is_hide`;
      }
    },
    startTimer() {
      this.initStatus().then(rs => {
        setTimeout(() => {
          if (this.destroyed) {
            return;
          }
          this.startTimer();
        }, 10000);
      });
    },
    initStatus() {
      return apiIngOrder().then(rs => {
        const { status, data } = rs;
        if (status == 200) {
          this.noRead = +data.fast_gm_no_read;
          // this.noRead = (+1);
        }
      });
    },
    getUeCustomerServer() {
      let params = {};
      if (this.userInfo) {
        //登陆
        let req = {};
        req.uid = this.userInfo.accountInfo.account_id;
        req.mobile = this.userInfo.accountInfo.mobile;
        req.email = this.userInfo.accountInfo.email;
        req.userName = this.userInfo.accountInfo.username;

        req.userIp = this.ipInfo;
        req.account = this.apiAssetsInfo;

        req.language = localStorage.getItem("language") || "zh_CN";
        // console.log(this.encrypt(this.changStr(req)));
        let tempData = this.encrypt(this.changStr(req));

        let domain = "https://kf.jhdami.com/";
        params.appid = "csc1679554722821";
        params.code = "BD6u7";
        params.channelType = "1";
        params.language = localStorage.getItem("language") || "zh_CN";
        params.nonceStr = "k6ih17s0ppm6esnw02it12";
        params.data = tempData;
        let sign = this.getSign(params);
        this.customer_service_url =
          domain + "?" + this.changStr(params) + "&sign=" + sign;
        // console.log("----", this.customer_service_url);
      } else {
        //未登录
        this.customer_service_url =
          "https://kf.jhdami.com?code=BD6u7&channelType=1&tenantPlatCode=youyi";
      }
    },
    changStr(req) {
      let str = "";
      for (let i in req) {
        str += i + "=" + req[i] + "&";
      }
      str = str.substr(0, str.length - 1);
      return str;
    },
    getSign(params) {
      for (let key in params) {
        params[key] =
          params[key] || params[key] === 0 || params[key] === false
            ? params[key]
            : "";
      }
      let keys = Object.keys(params);
      let length = keys.length;
      keys = keys.sort();
      let sign = "";
      for (let i = 0; i < length; i++) {
        if (sign != "") sign = sign + "&";
        sign = sign + keys[i] + "=" + params[keys[i]];
      }
      sign = md5(sign)
        .toString()
        .toUpperCase();
      return sign;
    },
    // 加密
    encrypt(word, keyStr) {
      keyStr = keyStr
        ? this.sort_ASCII(keyStr)
        : "PRoxb3f1yTIYX3IgZ6SUWqAcJEI3IDJG";
      let key = CryptoJS.enc.Utf8.parse(keyStr);
      let srcs = CryptoJS.enc.Utf8.parse(word);
      let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.toString();
    },
    // ascii从小到达排序
    sort_ASCII(arr) {
      arr.sort((a, b) => {
        return a.charCodeAt(0) - b.charCodeAt(0);
      });
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "remain",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        this.apiAssetsInfo = data.remain.u_valuation;
      }
    },
    async userLoginLogList() {
      if (!this.userInfo) {
        return;
      }
      let pamars = { page_size: 1 };
      await userLoginLogList(pamars).then(({ status, data, msg }) => {
        if (status === 200) {
          this.ipInfo = data.list[0].login_ip;
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      language: "user/language",
      userInfo: "user/userInfo",
    }),
    // ...mapState({
    //   userInfo: state => state.user.info,
    // }),
  },
  watch: {
    dialogVisible() {
      this.onChatClick();
    },
  },
};
</script>

<style lang="scss">
.chat-wedget {
  position: fixed;
  // right: 40px;
  right: 60px;
  bottom: 7%;
  .el-badge__content.is-fixed {
    top: -48px !important;
  }

  cursor: pointer;
  z-index: 99999;
  .chat-wedget-circle {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #c61b3d url("../../assets/img/chat-wedget-white.png") no-repeat
      center;
    background-size: 60%;
  }
  .chat-wedget-text {
    position: absolute;
    color: #c61b3d;
    top: 10px;
    right: -40px;
    width: 130px;
    text-align: center;
    font-size: 14px;
  }
  .chat-wedget-box {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 375px;
    height: 50vh;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    iframe {
      width: 100%;
      height: 100%;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 8px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .iconicon-close {
        color: white;
      }
    }
  }
}
</style>
